/* eslint-disable i18next/no-literal-string */
import { FeatureFlag, ReportMetric } from '@cbo/shared-library';
import { DashboardConfig } from '@cbo/shared-library/request/admin.request';
import { useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import * as yup from 'yup';
import { isEqual, orderBy } from 'lodash';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useSites } from '../../contexts/siteContext';
import { useUsers } from '../../contexts/userContext';
import { useGetDashboardGraphData, useGetKPIData } from '../../sales/requests/queries';
import { DashboardGraphDataResponse, ReportMetricResult } from '../../sales/requests/requests';
import { useUserPreferencesDataQuery } from '../requests/queries';
import { KPIResult, defaultKPIData, getRequestedMetrics, mapKPIResponse } from './DashboardUtils';
import ScorecardContainer from './ScorecardContainer/ScorecardContainer';
import ComparisonRange from '../../config/comparisonRange';
import DateFilter from '../../components/GlobalFilterBar/DateFilter/DateFilter';
import { GlobalFilterBarDateInput } from '../../components/GlobalFilterBar/types';
import { today } from '../../utils/reportingUtils/filterUtils';
import useYupValidationResolver from '../../utils/formUtils/yupValidationResolver';
import { usePackages } from '../../contexts/packageContext';
import { useFeatureFlags } from '../../utils/hooks/useFeatureFlag';
import routes from '../../constants/routes';

// 2 minutes
const REFRESH_TIMER = 120000;

function DashboardPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useUsers();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallMobile = useMediaQuery('(max-width:374px)');
  const { selectedSite } = useSites();
  const { data: userPreferencesData, isLoading: isUserPreferencesLoading } = useUserPreferencesDataQuery(
    user.fullyAuthenticated === 'authenticated'
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refreshIntervalRef: MutableRefObject<any> = useRef(0);
  const [isSessionActive] = useState(sessionStorage.getItem('isSessionActive'));
  const [dashboardConfiguration, setDashboardConfiguration] = useState<DashboardConfig[] | undefined>(undefined);
  const [KPIMap, setKPIMap] = useState<Map<number, KPIResult> | undefined>(undefined);
  const [netSalesInfo, setNetSalesInfo] = useState<{ reportMetricResult: ReportMetricResult; url: string } | undefined>(
    undefined
  );
  const [graphData, setGraphData] = useState<DashboardGraphDataResponse | undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [dateRangeQueryParam, setDateRangeQueryParam] = useState<string>('');
  const { kpiData, setRequest, isKPIDataLoading } = useGetKPIData();
  const { graphDataResponse, setGraphRequest, isGraphDataLoading } = useGetDashboardGraphData();
  const [cboLaborReports, cboTimezoneReports] = useFeatureFlags(FeatureFlag.laborReports, FeatureFlag.timezoneReports);
  const { starter } = usePackages();

  const defaultDateRange: GlobalFilterBarDateInput = {
    startDate: today,
    endDate: today,
  };
  const [selectedFilters, setSelectedFilters] = useState<GlobalFilterBarDateInput>();
  const formContext = useForm({
    mode: 'onChange',
    resolver: useYupValidationResolver(
      yup.object({
        dateRange: yup.object().shape({ startDate: yup.date().required(), endDate: yup.date().required() }),
      })
    ),
    defaultValues: {
      dateRange: defaultDateRange,
    },
  });
  const { watch } = formContext;
  const dateRange = watch('dateRange');

  useEffect(() => {
    if (
      !isEqual(selectedFilters, dateRange) &&
      !user.isOrgSwitching &&
      userPreferencesData?.dashboardConfiguration &&
      !isUserPreferencesLoading &&
      user.bslAuth?.sites &&
      Object.values(user.bslAuth.sites).some((site) => site.enterpriseUnitId === selectedSite.enterpriseUnitId)
    ) {
      setRequest({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        currentDate: today,
        metricsRequested: getRequestedMetrics(userPreferencesData.dashboardConfiguration),
      });
      setGraphRequest({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
      setDateRangeQueryParam(`?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`);
      setSelectedFilters(dateRange);
    }
  }, [
    dateRange,
    setRequest,
    setGraphRequest,
    userPreferencesData?.dashboardConfiguration,
    user.bslAuth?.sites,
    user.isOrgSwitching,
    isUserPreferencesLoading,
    selectedSite.enterpriseUnitId,
    refresh,
    selectedFilters,
  ]);

  useEffect(() => {
    if (kpiData && kpiData.reportMetricResults[ReportMetric.FORECAST_SALES]) {
      setKPIMap(mapKPIResponse(kpiData));
      if (kpiData.reportMetricResults[ReportMetric.NET_SALES]) {
        setNetSalesInfo({
          reportMetricResult: kpiData.reportMetricResults[ReportMetric.NET_SALES],
          url: userPreferencesData?.dashboardConfiguration?.length
            ? userPreferencesData.dashboardConfiguration[0].url + dateRangeQueryParam
            : '',
        });
      }
    }
  }, [kpiData, isKPIDataLoading, userPreferencesData?.dashboardConfiguration, dateRangeQueryParam]);

  useEffect(() => {
    if (graphDataResponse) {
      let actualSalesSum = 0;
      let comparisonSalesSum = 0;
      let forecastedSalesSum = 0;
      const actualSales = graphDataResponse.netSales.map((value) => {
        actualSalesSum += value;
        return actualSalesSum;
      });
      const comparisonSales = graphDataResponse.comparisonNetSales.map((value) => {
        comparisonSalesSum += value;
        return comparisonSalesSum;
      });
      const forecastedSales = graphDataResponse.forecastedNetSales.map((value) => {
        forecastedSalesSum += value;
        return forecastedSalesSum;
      });
      setGraphData({
        ...graphDataResponse,
        netSales: actualSales,
        comparisonNetSales: comparisonSales,
        forecastedNetSales: forecastedSales,
      });
    }
  }, [graphDataResponse, isGraphDataLoading]);

  useEffect(() => {
    if (userPreferencesData && !isUserPreferencesLoading) {
      const sortedConfiguration: DashboardConfig[] | undefined = orderBy(
        userPreferencesData.dashboardConfiguration,
        [(config) => config.order],
        ['asc']
      );

      let filterOut = !starter ? [1421, 687, 1719] : [];
      if (!cboLaborReports) {
        filterOut = [...filterOut, 1417, 685, 1716];
      }

      const filtered =
        filterOut.length < 1
          ? sortedConfiguration
          : sortedConfiguration.filter((config) => {
              // TODO: ASM-3355 will fix this looker hardcoded mapping
              const hasLookId = config.lookIds.find((x) => filterOut.includes(x.lookId));
              return !hasLookId;
            });

      setDashboardConfiguration(filtered);
    }
  }, [userPreferencesData, isUserPreferencesLoading, starter, cboLaborReports]);

  useEffect(() => {
    if (!isEqual(selectedFilters, dateRange) && selectedSite) {
      setKPIMap(undefined);

      clearInterval(refreshIntervalRef.current);

      if (today === dateRange.startDate || today === dateRange.endDate) {
        refreshIntervalRef.current = setInterval(() => {
          setRefresh((toggleRefresh) => !toggleRefresh);
        }, REFRESH_TIMER);
      }
    }

    return () => {
      clearInterval(refreshIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, selectedSite]);

  useEffect(() => {
    if (!isSessionActive) sessionStorage.setItem('isSessionActive', 'true');
  }, [isSessionActive]);

  const disableUrlBasedOnFeatureFlag = (url: string) => {
    let finalUrl: string | undefined;

    const featureFlags = [
      {
        flag: cboTimezoneReports,
        url: routes.INTERVAL_SALES_AND_LABOR,
      },
      {
        flag: cboLaborReports,
        url: routes.EMPLOYEE_SALES_PERFORMANCE,
      },
    ];

    for (let i = 0; i < featureFlags.length; i += 1) {
      if (
        (featureFlags[i].flag && url.includes(featureFlags[i].url)) ||
        (!url.includes(routes.INTERVAL_SALES_AND_LABOR) && !url.includes(routes.EMPLOYEE_SALES_PERFORMANCE))
      ) {
        finalUrl = url;
        break;
      } else {
        finalUrl = undefined;
      }
    }

    return finalUrl;
  };
  return (
    <Box
      sx={{
        height: '100%',
        paddingX: isMobile ? 2 : 4,
        paddingY: 2,
      }}
      data-testid='dashboard-page'
      display='block'
    >
      {/* Date range filter */}
      <Box
        sx={{
          display: 'flex',
          marginBottom: 4,
          width: isMobile ? '100%' : 'auto',
        }}
        data-testid='date-range-picker'
      >
        <FormContainer formContext={formContext}>
          <DateFilter controllerName='dateRange' onlyFilterContainer={false} predefined />
        </FormContainer>
      </Box>

      {/* Scorecards */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: isSmallMobile ? 'column' : 'row',
          gap: isMobile ? '16px' : '24px',
        }}
        data-testid='dashboard-scorecards'
      >
        {!!dashboardConfiguration &&
          dashboardConfiguration.map((lookConfig: DashboardConfig, i: number) => (
            <ScorecardContainer
              key={lookConfig.order}
              scaleStyle={lookConfig.style}
              singleValueCardProps={{
                title: t(KPIMap?.get(lookConfig.lookIds[0].lookId)?.cardInfo.title ?? defaultKPIData.title),
                value: KPIMap?.get(lookConfig.lookIds[0].lookId)?.cardInfo.value ?? defaultKPIData.value,
                url: disableUrlBasedOnFeatureFlag(lookConfig.url + dateRangeQueryParam),
                loading: !KPIMap,
                currency: KPIMap?.get(lookConfig.lookIds[0].lookId)?.cardInfo.currency ?? defaultKPIData.currency,
                percentage: KPIMap?.get(lookConfig.lookIds[0].lookId)?.cardInfo.percentage,
                positiveResultsPreferred: !!KPIMap?.get(lookConfig.lookIds[0].lookId)?.cardInfo
                  .positiveResultsPreferred,
                comparisonRange: ComparisonRange.YEAR,
                comparisonValue:
                  KPIMap?.get(lookConfig.lookIds[0].lookId)?.cardInfo.change ?? defaultKPIData.comparisonValue,
                isClickable: starter,
              }}
              lineChartProps={{
                singleValueProps: {
                  title: t('admin.dashboardPage.KPICards.netSales'),
                  value: netSalesInfo?.reportMetricResult.currentValue ?? 0,
                  comparisonValue: netSalesInfo?.reportMetricResult.change ?? 0,
                  comparisonRange: ComparisonRange.YEAR,
                  positiveResultsPreferred: true,
                  currency: true,
                },
                url: netSalesInfo?.url ?? '',
                loading: !graphData || !KPIMap,
                isSameDay: dateRange.startDate === dateRange.endDate,
                data: {
                  xAxis: graphData?.xAxis ?? [],
                  netSales: graphData?.netSales ?? [],
                  comparisonNetSales: graphData?.comparisonNetSales ?? [],
                  forecastedNetSales: graphData?.forecastedNetSales ?? [],
                },
              }}
            />
          ))}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '142px',
            width: isSmallMobile ? '100%' : '185px',
            overflow: 'clip',
            flex: isMobile ? '40%' : 'none',
          }}
          hidden
        />
      </Box>
    </Box>
  );
}

export default DashboardPage;
