import { IDateRange, Period } from '@cbo/shared-library';
import { FiscalCalendarFilters } from '@cbo/shared-library/response/calendar.response';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import { SxProps, Theme, styled, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import {
  DateRange,
  DateRangePickerDay,
  DateRangePickerDayProps,
  DateRangePickerToolbar,
  DateRangePickerToolbarProps,
  PickersShortcutsItem,
  PickersShortcutsProps,
} from '@mui/x-date-pickers-pro-7';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { uniqueId } from 'lodash';
import React from 'react';
import { findCalendarFilter } from '../../../utils/reportingUtils/filterUtils';
import { chipBackgroundColor, darkBackgroundColor } from '../GlobalFilterBarStyles';
import { DateFilterChip } from '../types';

// This is nearly exactly the same toolbar used by default in StaticDateRangePicker
// This code was imported from MUI, but with the minor change of adding a close button
export function CustomToolbar(props: DateRangePickerToolbarProps<Dayjs>, handleClose: () => void) {
  const { className } = props;
  const theme = useTheme();

  return (
    <Grid container direction='row' justifyContent='space-between' className={className}>
      <DateRangePickerToolbar {...props} />
      <Grid item justifyContent='flex-end'>
        <IconButton
          sx={{
            marginTop: '10px',
            marginRight: '10px',
            '&:focus-visible': {
              boxShadow: 'none',
              outline: `2px solid ${theme.palette.primary.dark}`,
            },
          }}
          onClick={handleClose}
          data-testid='toolbar-clear'
          aria-label={t('buttonText.close')}
        >
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export function CustomPickersShortcuts<TValue>(
  props: PickersShortcutsProps<TValue>,
  chips: PickersShortcutsItem<TValue>[] | undefined,
  controllerName: string,
  dates: DateRange<dayjs.Dayjs>
) {
  const { items, isLandscape, onChange, isValid, ...other } = props;
  const chipValues = chips;

  // While loading, we want to show a skeleton loader for each chip
  if (items == null || items.length === 0) {
    return (
      <List
        // key={items.length}
        dense
        sx={[
          {
            // https://github.com/mui/mui-x/blob/83b26950f73d778c1eaf8d1281928b7fd0b5d0ac/packages/x-date-pickers/src/PickersShortcuts/PickersShortcuts.tsx
            // This is weird, since normally mui relies on a VIEW_HEIGHT internally to determine the maxHeight of a list
            // I gathered the maxHeight from the x-date-pickers source code and added it here, which is working
            maxHeight: '336px',
            width: 180,
            overflow: 'auto',
          },
        ]}
        {...other}
      >
        {Array.from({ length: 10 }, (_, idx) => (
          <ListItem key={uniqueId()}>
            <Skeleton variant='rounded' sx={{ borderRadius: '25px' }}>
              {/* I include labels here so that the skeleton adapts to the chip size. They are never actually shown. This just makes the skeletons look prettier. */}
              {idx % 2 === 0 ? <Chip label='Loading' /> : <Chip label='LoadingLoading' />}
            </Skeleton>
          </ListItem>
        ))}
      </List>
    );
  }

  const resolvedItems = items.map(({ getValue, ...item }) => {
    const newValue = getValue({ isValid });

    return {
      ...item,
      label: item.label,
      onClick: () => {
        onChange(newValue, 'accept', item);
      },
      disabled: !isValid(newValue),
    };
  });

  // Returns blue if the button is clicked, otherwise return the default color
  const handleChipSx = (idx: number): SxProps<Theme> | undefined => {
    let getValue;
    if (chipValues) {
      getValue = chipValues[idx].getValue;
    }
    if (
      chips &&
      getValue &&
      dates[0]?.isSame((getValue({ isValid }) as unknown as Dayjs[])[0], 'day') &&
      dates[1]?.isSame((getValue({ isValid }) as unknown as Dayjs[])[1], 'day')
    ) {
      return {
        backgroundColor: chipBackgroundColor,
        color: darkBackgroundColor,
        '&:hover': {
          backgroundColor: chipBackgroundColor,
          color: darkBackgroundColor,
        },
      };
    }
    return {
      backgroundColor: '',
      color: '',
    };
  };

  return (
    <List
      key={items.length}
      dense
      sx={[
        {
          // https://github.com/mui/mui-x/blob/83b26950f73d778c1eaf8d1281928b7fd0b5d0ac/packages/x-date-pickers/src/PickersShortcuts/PickersShortcuts.tsx
          // This is weird, since normally mui relies on a VIEW_HEIGHT internally to determine the maxHeight of a list
          // I gathered the maxHeight from the x-date-pickers source code and added it here, which is working
          maxHeight: '336px',
          maxWidth: 200,
          overflow: 'auto',
        },
        ...(Array.isArray(other.sx) ? other.sx : [other.sx]),
      ]}
      {...other}
    >
      {resolvedItems.map((item, idx) => (
        <ListItem key={uniqueId()}>
          <Chip id={item.label} {...item} sx={handleChipSx(idx)} />
        </ListItem>
      ))}
    </List>
  );
}

export function getDefaultChips(res: FiscalCalendarFilters, disableFutureDates: boolean) {
  const calendarWeek = findCalendarFilter(res.periods, Period.CALENDAR_WEEK_END) as IDateRange;
  const lastCalendarWeek = findCalendarFilter(res.periods, Period.LAST_CALENDAR_WEEK_END) as IDateRange;
  const calendarMonth = findCalendarFilter(res.periods, Period.CALENDAR_MONTH_END) as IDateRange;
  const lastCalendarMonth = findCalendarFilter(res.periods, Period.LAST_CALENDAR_MONTH_END) as IDateRange;
  const calendarYear = findCalendarFilter(res.periods, Period.CALENDAR_YEAR_END) as IDateRange;
  const lastCalendarYear = findCalendarFilter(res.periods, Period.LAST_CALENDAR_YEAR_END) as IDateRange;
  const fiscalWeek = findCalendarFilter(res.periods, Period.FISCAL_WEEK_END) as IDateRange;
  const lastFiscalWeek = findCalendarFilter(res.periods, Period.LAST_FISCAL_WEEK_END) as IDateRange;
  const fiscalMonth = findCalendarFilter(res.periods, Period.FISCAL_MONTH_END) as IDateRange;
  const lastFiscalMonth = findCalendarFilter(res.periods, Period.LAST_FISCAL_MONTH_END) as IDateRange;
  const fiscalYear = findCalendarFilter(res.periods, Period.FISCAL_YEAR_END) as IDateRange;
  const lastFiscalYear = findCalendarFilter(res.periods, Period.LAST_FISCAL_YEAR_END) as IDateRange;

  const isDateDisabled = (date: Dayjs) => (date > dayjs() && disableFutureDates ? dayjs() : date);

  // Format the date ranges to be used in the date filter chips
  const todayChip: DateFilterChip = {
    label: t('sales.dateRanges.today'),
    dates: [dayjs(), dayjs()],
  };

  const yesterdayChip: DateFilterChip = {
    label: t('sales.dateRanges.yesterday'),
    dates: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
  };

  const weekChip: DateFilterChip = {
    label: t('sales.dateRanges.week'),
    dates: [dayjs(calendarWeek.startDate), isDateDisabled(dayjs(calendarWeek.endDate))],
  };

  const lastWeekChip: DateFilterChip = {
    label: t('sales.dateRanges.lastWeek'),
    dates: [dayjs(lastCalendarWeek.startDate), isDateDisabled(dayjs(lastCalendarWeek.endDate))],
  };

  const monthChip: DateFilterChip = {
    label: t('sales.dateRanges.month'),
    dates: [dayjs(calendarMonth.startDate), isDateDisabled(dayjs(calendarMonth.endDate))],
  };

  const lastMonthChip: DateFilterChip = {
    label: t('sales.dateRanges.lastMonth'),
    dates: [dayjs(lastCalendarMonth.startDate), isDateDisabled(dayjs(lastCalendarMonth.endDate))],
  };

  const yearChip: DateFilterChip = {
    label: t('sales.dateRanges.year'),
    dates: [dayjs(calendarYear.startDate), isDateDisabled(dayjs(calendarYear.endDate))],
  };

  const lastYearChip: DateFilterChip = {
    label: t('sales.dateRanges.lastYear'),
    dates: [dayjs(lastCalendarYear.startDate), isDateDisabled(dayjs(lastCalendarYear.endDate))],
  };

  const fiscalWeekChip: DateFilterChip = {
    label: t('sales.dateRanges.fiscalWeek'),
    dates: [dayjs(fiscalWeek.startDate), isDateDisabled(dayjs(fiscalWeek.endDate))],
  };

  const lastFiscalWeekChip: DateFilterChip = {
    label: t('sales.dateRanges.lastFiscalWeek'),
    dates: [dayjs(lastFiscalWeek.startDate), isDateDisabled(dayjs(lastFiscalWeek.endDate))],
  };

  const fiscalMonthChip: DateFilterChip = {
    label: t('sales.dateRanges.fiscalMonth'),
    dates: [
      dayjs(fiscalMonth.startDate),
      dayjs(fiscalMonth.endDate) > dayjs() && disableFutureDates ? dayjs() : dayjs(fiscalMonth.endDate),
    ],
  };

  const lastFiscalMonthChip: DateFilterChip = {
    label: t('sales.dateRanges.lastFiscalMonth'),
    dates: [dayjs(lastFiscalMonth.startDate), isDateDisabled(dayjs(lastFiscalMonth.endDate))],
  };

  const fiscalYearChip: DateFilterChip = {
    label: t('sales.dateRanges.fiscalYear'),
    dates: [dayjs(fiscalYear.startDate), isDateDisabled(dayjs(fiscalYear.endDate))],
  };

  const lastFiscalYearChip: DateFilterChip = {
    label: t('sales.dateRanges.lastFiscalYear'),
    dates: [dayjs(lastFiscalYear.startDate), isDateDisabled(dayjs(lastFiscalYear.endDate))],
  };

  return [
    todayChip,
    yesterdayChip,
    weekChip,
    lastWeekChip,
    monthChip,
    lastMonthChip,
    yearChip,
    lastYearChip,
    fiscalWeekChip,
    lastFiscalWeekChip,
    fiscalMonthChip,
    lastFiscalMonthChip,
    fiscalYearChip,
    lastFiscalYearChip,
  ];
}

// This is a styled version of the DateRangePickerDay component from MUI for selecting only weeks at a time in the date filter
export const DateRangePickerDayStyled = styled(DateRangePickerDay)(
  ({ theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting, isStartOfPreviewing, isEndOfPreviewing }) => ({
    ...(theme && {
      '.MuiDateRangePickerDay-rangeIntervalDayPreviewEnd': {
        border: '2px solid transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
      },
      '.MuiDateRangePickerDay-rangeIntervalPreview.MuiDateRangePickerDay-rangeIntervalDayPreview': {
        border: '2px solid transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
      },
    }),
    ...(isHighlighting && {
      pointerEvents: 'none',
    }),
    ...(!(isStartOfPreviewing || isEndOfPreviewing || isStartOfHighlighting || isEndOfHighlighting) && {
      '.MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day:focus': {
        backgroundColor: 'transparent',
      },
    }),
    ...(isStartOfHighlighting &&
      isEndOfHighlighting && {
        '.MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day:focus': {
          backgroundColor: 'inherit',
        },
      }),
  })
) as React.ComponentType<DateRangePickerDayProps<Dayjs>>;
