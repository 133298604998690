import { Res } from '@cbo/shared-library';
import { CurrencyFormat } from '../../models/config';
import { Regions, TRegion } from '../../config';

/**
 * Returns the currency format, combining regional preferences with the user's saved preferences
 * @param preferences
 * @returns Returns an instance of CurrencyFormat that includes locale and format options
 */
export const getCurrencyFormat = (preferences?: Res.Admin.UserPreferences | null): CurrencyFormat => {
  const region: TRegion = (preferences?.region as TRegion) || 'en-US';
  const locale: string = Regions[region].locale || region;
  return { ...Regions[region].formatting.currency, locale };
};

export const getCurrencySymbol = (preferences?: Res.Admin.UserPreferences | null): string => {
  const currencyFormat = getCurrencyFormat(preferences);
  const formatter = new Intl.NumberFormat(currencyFormat.locale, {
    style: 'currency',
    currency: currencyFormat.options?.currency,
  });

  const parts = formatter.formatToParts(1);
  const currencySymbol = parts.find((part) => part.type === 'currency');

  return currencySymbol ? currencySymbol.value : '';
};

/**
 * Formats currency based on regional preferences and the user's saved preferences
 * @param value
 * @param preferences
 * @param format
 * @returns Returns the specified currency (value) as formatted string
 */
export const formatCurrency = (
  value: number,
  preferences?: Res.Admin.UserPreferences | null,
  format?: CurrencyFormat,
  useCompact?: boolean,
  maxDigits = 2
): string => {
  const { options, locale } = format || getCurrencyFormat(preferences);
  const opts: Intl.NumberFormatOptions = { ...(options as Intl.NumberFormatOptions), style: 'currency' };

  return preferences?.showCurrencySymbol !== undefined && !preferences.showCurrencySymbol
    ? Intl.NumberFormat(locale, {
        ...opts,
        ...(useCompact && { notation: 'compact', maximumFractionDigits: maxDigits }),
      })
        .formatToParts(value)
        .filter((x) => x.type !== 'currency')
        .map((x) => x.value)
        .join('')
        .trim()
    : Intl.NumberFormat(locale, {
        ...opts,
        ...(useCompact && { notation: 'compact', maximumFractionDigits: maxDigits }),
      }).format(value);
};
