import { Employee as EmployeeObject, Gender } from '@cbo/shared-library';
import { ActivationTypes, ActivationTypes as EmploymentStatus } from '@cbo/shared-library/types/activation';
import { useCallback } from 'react';
import useRequestToDelete from '../requestsToDelete';
import { BaseEntity, EmployeeRequestSchema, LaborGroupSchema } from '../types';

export interface ContactInformation extends BaseEntity {
  emailAddress: string;
  lastName: string;
  firstName: string;
  middleName: null | string;
  nickname: null | string;
  phoneNumber: string;
  alternatePhoneNumber: null | string;
  formCompletionStatus: EmployeeObject.EmployeeFormCompletionStatus;
}

export interface EmergencyContactInformation extends BaseEntity {
  name: string;
  relationship: EmployeeObject.Relationship; // enum
  primaryPhoneNumber: string;
  alternatePhoneNumber: null | string;
  emailAddress: string;
  formCompletionStatus: EmployeeObject.EmployeeFormCompletionStatus;
}

export interface PersonalInformation extends BaseEntity {
  birthDate: string;
  gender: Gender;
  address1: string;
  address2: string;
  city: string;
  state: string;
  county: string;
  postalCode: string;
  country: string;
  picture?: string;
  formCompletionStatus: EmployeeObject.EmployeeFormCompletionStatus;
}

export interface EmployeeCertification extends BaseEntity {
  id: string;
  employee?: string;
  formCompletionStatus: EmployeeObject.EmployeeFormCompletionStatus;
  certificationName?: string;
  certificationReference?: string;
  certificationExpirationDate?: Date;
  excluded?: boolean;
}

export interface JobCodeDefinition extends BaseEntity {
  id: string;
  // EmployeeConfigurationsResponse is defined below, however, EmployeeConfigurationsResponse includes JobCodeDefinition. Therefore, suppressing eslint error here.
  // eslint-disable-next-line no-use-before-define
  employees?: EmployeeConfigurationsResponse[];
  nepEnterpriseOrganization?: string;
  nepEnterpriseUnit?: string;
  jobCodeDescription?: string;
  jobCodeName?: string;
  payRate?: number | string;
  breakConfig?: string;
  posJobCodeRole?: string;
  posJobCodeID?: string;
  posJobCodeName?: string;
  posAccessLevelID?: string;
  activationStatus: ActivationTypes;
  activationReason?: string;
  activationDetails?: string;
  activationEffectiveDate?: Date;
  laborGroup?: LaborGroupSchema;
  glCode?: string;
  excludeFromPayrollReport?: boolean;
  excludeHoursAndDollarsFromScheduleMetrics?: boolean;
}

export interface EmployeeJobCodeAssignment extends BaseEntity {
  id: string;
  employeeId: string;
  jobCodeId: string;
  employeeJobCodeRating: number;
}

export interface Employee extends BaseEntity {
  id: string;
  exportId: string;
  orgId: string;
  employeeRegistrationStatus: EmployeeObject.RegistrationStatus;
  contactInformation: ContactInformation;
  personalInformation: PersonalInformation;
  emergencyContact: EmergencyContactInformation;
  certifications: EmployeeCertification[];
}

export enum EmploymentReason {
  NEW_HIRE = 'NEW_HIRE',
  REHIRE = 'REHIRE',
  RETURN_FROM_LEAVE = 'RETURN_FROM_LEAVE',
  TRANSFERRED_IN = 'TRANSFERRED_IN',
}

export enum JobStatus {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  HOURLY = 'HOURLY',
  SALARY_EXEMPT = 'SALARY_EXEMPT',
  SALARY_NON_EXEMPT = 'SALARY_NON_EXEMPT',
}

// TODO: Remove/update enum once LRE is available
export enum LaborWarning {
  PART_TIME_CRITICAL = 'PART_TIME_CRITICAL',
  OVERTIME_WARNING = 'OVERTIME_WARNING',
  OVERTIME_CRITICAL = 'OVERTIME_CRITICAL',
}

export interface PayRate extends BaseEntity {
  id?: string;
  nepEnterpriseUnit?: string;
  nepEnterpriseOrganization?: string;
  payRate?: number | null;
  activationStatus?: ActivationTypes;
  activationReason?: string;
  activationDetails?: string;
  activationEffectiveDate?: string;
}

export interface AddPayRateRequestBody {
  employeeConfigurationId?: string;
  payRate?: number;
  activationStatus?: ActivationTypes;
  activationReason?: string;
  activationDetails?: string;
  activationEffectiveDate?: Date;
}

export interface PatchPayRateRequestBody {
  payRate?: number;
  activationStatus?: string;
  activationReason?: string;
  activationDetails?: string;
  activationEffectiveDate?: Date;
}

export interface EmployeeConfigurationsResponse extends BaseEntity {
  id: string;
  employeeId: string;
  nepEnterpriseUnit: string;
  nepEnterpriseOrganization: string;
  employmentStatusData: string | null;
  startDate: Date | null;
  rehireDate: Date | null;
  scheduleGroup: number | null;
  activationStatus?: EmploymentStatus | null;
  activationReason?: string | null;
  activationDetails?: string | null;
  activationEffectiveDate?: Date | null;
  jobStatus: JobStatus | null;
  reportingDate: Date | null;
  expectedReturnDateFromLeaveOfAbsence: string | null;
  doNotImportPosTimePunches: boolean | null;
  terminationDate: Date | null;
  posEmployeeId: string | null;
  useMagneticCards: boolean | null;
  useDallasKey: boolean | null;
  prefersQwertyKeyboard: boolean | null;
  jobCodes: JobCodeDefinition[];
  payRates: PayRate[];
  employeeInformation: Employee | null;
  eligibleForRehire: boolean | null;
  useFingerPrintScannerForClockIn: boolean | null;
  useFingerPrintScannerForLoginAndManagerApproval: boolean | null;
  employeeJobcodeAssignments: EmployeeJobCodeAssignment[];
}

// this needs extra logic around it to pass logic to update the specific status according to whatever is called
const useUpdateEmployeeRegistrationStatus = () => {
  const { getEmployee, updateEmployee } = useRequestToDelete();
  return useCallback(
    async (
      employeeId: string,
      status: EmployeeObject.RegistrationStatus,
      handleError: (error: unknown) => void,
      enterpriseUnitId: string,
      errorMessageMapper?: Record<string, string>
    ) => {
      const employee = await getEmployee(employeeId, handleError);
      if (employee) {
        employee.employeeRegistrationStatus = status;
        const updatedRegistration: EmployeeRequestSchema = {
          employeeDto: {
            employeeRegistrationStatus: status,
          },
          contactInformationDto: {
            emailAddress: employee.contactInformation.emailAddress,
            lastName: employee.contactInformation.lastName,
            firstName: employee.contactInformation.firstName,
            phoneNumber: employee.contactInformation.phoneNumber,
          },
        };
        const result = await updateEmployee(
          employeeId,
          updatedRegistration,
          handleError,
          enterpriseUnitId,
          errorMessageMapper
        );
        return result;
      }
      return false;
    },
    [getEmployee, updateEmployee]
  );
};

export { useUpdateEmployeeRegistrationStatus };
