import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface CustomActiveCellProps {
  isActive: boolean;
}

function CustomActiveCell({ isActive }: CustomActiveCellProps) {
  const { t } = useTranslation();
  return (
    <Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
      {isActive ? (
        <Check color='success' data-testid='activeCell-active' aria-label={t('buttonText.yes')} />
      ) : (
        <Close color='error' data-testid='activeCell-inactive' aria-label={t('buttonText.no')} />
      )}
    </Box>
  );
}

export default CustomActiveCell;
