import i18next from 'i18next';
import { CboRole } from '@cbo/shared-library';
import isPermitted from '../../../lib/permissions';
import { useUsers } from '../../../contexts/userContext';
import routes from '../../../constants/routes';
import { NavItem } from '.';

export default function useCompanyLinksRoutes(companyLinks: NavItem[]): NavItem[] {
  const user = useUsers();

  const companyLinksRoutes: NavItem[] = [];

  if (isPermitted(user, [CboRole.LINK_ADMIN])) {
    companyLinksRoutes.push({
      title: i18next.t('navItems.editCompanyLinks'),
      href: routes.EDIT_COMPANY_LINKS,
      testid: 'edit-links-menu-item',
    });
  }

  return [...companyLinksRoutes, ...companyLinks];
}
