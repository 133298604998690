import { CboRole } from '@cbo/shared-library';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../constants/routes';
import { useUsers } from '../../contexts/userContext';
import isPermitted from '../../lib/permissions';

type RequirePermissionProps = {
  permissions: CboRole[];
  requireAuth?: boolean;
  hideChild?: boolean;
  children: JSX.Element;
};

function RequirePermission(props: RequirePermissionProps) {
  const { children, permissions, requireAuth, hideChild } = props;
  const history = useHistory();
  const user = useUsers();
  const isAuthenticated = user.fullyAuthenticated;
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (isAuthenticated === 'undetermined') return;
    if (isAuthenticated === 'unauthenticated') {
      history.push(routes.HOME_PAGE);
    } else if (user && requireAuth && permissions.length === 0) {
      setHasPermission(true);
    } else if (user && !isPermitted(user, permissions)) {
      // Validating Authorization
      if (!hideChild) {
        history.push(routes.NOT_AUTHORIZED);
      }
      setHasPermission(false);
    } else if (user && isPermitted(user, permissions)) {
      setHasPermission(true);
    }
  }, [user, permissions, isAuthenticated, history, hideChild, requireAuth]);

  return isAuthenticated === 'undetermined' || !hasPermission ? <div data-testid='require-permission' /> : children;
}

RequirePermission.defaultProps = {
  requireAuth: false,
  hideChild: false,
};

export default RequirePermission;
