import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import type { NCRThemeOptions } from '@ncr-design-system/shared-frontend';
import { createTheme } from '@ncr-design-system/shared-frontend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';
import './index.css';
import LoginRouter from './pages/LoginRouter/LoginRouter';
import { AuthWrapper } from './shared/auth/AuthWrapper/AuthWrapper';
import { SnackbarProvider } from './shared/contexts/SnackbarContext';
import AccountPreferencesContextProvider from './shared/contexts/accountPreferencesContext';
import CompanyLinksContextProvider from './shared/contexts/companyLinksContext';
import DialogContextProvider from './shared/contexts/dialogContext';
import { ErrorRetryContextProvider } from './shared/contexts/errorRetryContext';
import FilterContextProvider from './shared/contexts/filterContext';
import { FirebaseApiContextWrapper } from './shared/contexts/firebaseApiContext';
import NotificationContextProvider from './shared/contexts/notificationContext';
import SitesContextProvider from './shared/contexts/siteContext';
import UserContextProvider from './shared/contexts/userContext';
import AppConfiguration from './shared/models/AppConfiguration';
import CommonComponentWrapper from './shared/org/CommonComponentWrapper';
import PackageContextProvider from './shared/contexts/packageContext';

interface AppProps {
  config: AppConfiguration;
}

declare module '@mui/material/styles' {
  interface PaletteOptions {
    other: {
      divider: string;
    };
    graphColors?: string[];
  }
}

const queryClient = new QueryClient({ defaultOptions: { queries: { useErrorBoundary: true } } });

export default function App(props: AppProps) {
  const { config } = props;
  const { t } = useTranslation();
  const [darkMode, setDarkMode] = useState(false);
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);

  useEffect(() => {
    const app = initializeApp(config);
    setFirebaseApp(app);
  }, [config]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-N9JJ3R7',
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  // TODO: Load Screen
  if (!firebaseApp) {
    return null;
  }

  // TODO: add rest of theme options, and likely move to separate file
  const lightTheme: NCRThemeOptions = {
    palette: {
      mode: 'light',
      background: {
        default: '#FAFAFA',
        paper: '#FFF',
      },
      other: {
        divider: '#BFBFBF',
      },
      graphColors: ['#203A7E', '#4A5AFF', '#069887', '#D07420', '#B03569', '#C461C6', '#D85337', '#5F249F'],
    },
  };

  const darkTheme: NCRThemeOptions = {
    palette: {
      mode: 'dark',
      background: {
        default: '#303030',
        paper: '#292929',
      },
      other: {
        divider: '#BFBFBF',
      },
      graphColors: ['#5283E5', '#8A9BFF', '#07C5A3', '#FFB747', '#DC4C88', '#C589DC', '#E17B65', '#9E63DE'],
    },
  };

  // TODO: Swap the palette mode, not theme
  const theme = createTheme(darkMode ? darkTheme : lightTheme);

  theme.typography.subtitle1.fontSize = theme.typography.pxToRem(16);
  theme.typography.subtitle1.fontWeight = 500;
  theme.typography.subtitle2.fontSize = theme.typography.pxToRem(14);
  theme.typography.subtitle2.fontWeight = 500;
  // Overriding ncr-shared-library styling for radio icons
  if (theme.components && theme.components.MuiRadio && theme.components.MuiRadio.defaultProps) {
    theme.components.MuiRadio.defaultProps = {
      icon: <RadioButtonUnchecked />,
      checkedIcon: <RadioButtonChecked color='primary' />,
    };
  }

  if (theme.components && theme.components.MuiCheckbox && theme.components.MuiCheckbox.defaultProps) {
    theme.components.MuiCheckbox.defaultProps = {
      sx: { color: theme.palette.grey[500] },
    };
  }

  setLocale({
    mixed: {
      required: t('formMessage.required'),
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthWrapper>
        <CommonComponentWrapper>
          <NotificationContextProvider>
            <FirebaseApiContextWrapper app={firebaseApp}>
              <UserContextProvider vapidKey={config.vapidKey}>
                <PackageContextProvider>
                  <SitesContextProvider>
                    <AccountPreferencesContextProvider setDarkMode={setDarkMode} preferencesToSet={null}>
                      <ThemeProvider theme={theme}>
                        <CssBaseline enableColorScheme />
                        <SnackbarProvider>
                          <ErrorRetryContextProvider>
                            <CompanyLinksContextProvider linksToSet={null} sidebarLinksToSet={null}>
                              <DialogContextProvider>
                                <FilterContextProvider>
                                  <LoginRouter apiRoot={config.apiRoot} ncridUrl={config.ncridUrl} />
                                </FilterContextProvider>
                              </DialogContextProvider>
                            </CompanyLinksContextProvider>
                          </ErrorRetryContextProvider>
                        </SnackbarProvider>
                      </ThemeProvider>
                    </AccountPreferencesContextProvider>
                  </SitesContextProvider>
                </PackageContextProvider>
              </UserContextProvider>
            </FirebaseApiContextWrapper>
          </NotificationContextProvider>
        </CommonComponentWrapper>
      </AuthWrapper>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
