import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Ty from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TypographyTypeMap, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';

export type HeaderProps = {
  title: string;
  description?: string;
  additionalDescription?: string | React.ReactNode;
  dataTestId: string;
  maxDescriptionWidth?: string;
  variant?: TypographyTypeMap['props']['variant'];
  chip?: {
    color: 'primary' | 'default' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
    label: string;
  };
  isReportHeader?: boolean;
  action?: React.ReactNode;
  headerAction?: React.ReactNode;
};

function Header(props: HeaderProps) {
  const {
    title,
    description,
    additionalDescription,
    dataTestId,
    maxDescriptionWidth,
    variant,
    chip,
    isReportHeader,
    action,
    headerAction,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <header>
      <Box
        mt={isMobile ? 1 : 0}
        mb={isReportHeader ? 1 : 2}
        data-testid={dataTestId}
        display='flex'
        justifyContent='flex-start'
      >
        <Ty data-testid='header-text' variant={variant} flexGrow={1}>
          {title}
          {chip ? <Chip color={chip.color} label={chip.label} sx={{ marginLeft: theme.spacing(1) }} /> : null}
        </Ty>
        {headerAction && (
          <Grid mb={2} display='flex' justifyContent='flex-end' alignItems='end'>
            <Grid> {headerAction} </Grid>
          </Grid>
        )}
      </Box>
      <Grid container mb={1}>
        <Grid xs={8} item>
          {description ? (
            <Box data-testid='description' sx={{ maxWidth: maxDescriptionWidth }} mb={2}>
              {description}
            </Box>
          ) : null}
          {additionalDescription ? (
            <Box sx={{ maxWidth: maxDescriptionWidth }} mb={2}>
              {additionalDescription}
            </Box>
          ) : null}
        </Grid>
        {action && (
          <Grid item sx={{ marginBottom: 2 }} display='flex' justifyContent='flex-end' alignItems='end' xs={4}>
            <Grid item> {action} </Grid>
          </Grid>
        )}
      </Grid>
    </header>
  );
}

Header.defaultProps = {
  description: '',
  additionalDescription: '',
  maxDescriptionWidth: '70%',
  variant: 'h5',
  chip: undefined,
  isReportHeader: false,
  action: null,
  headerAction: null,
};

export default Header;
