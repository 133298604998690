/* eslint-disable no-nested-ternary */
/* eslint-disable i18next/no-literal-string */

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { Dictionary, each } from 'lodash';
import { useSnackbar } from '../../contexts/SnackbarContext';
import Header from '../../components/Header/Header';
import {
  LaborRuleConfigData,
  LaborRuleGroup,
  MinimumWageRule,
  RuleChangeReason,
  RuleFormValues,
  WagesRuleTypes,
  SchedulingRuleTypes,
  MinorRuleTypes,
} from '../models/LaborRuleConfiguration';
import {
  getEmptyRuleConfig,
  getRuleFormValues,
  getTemplateString,
  organizeSites,
  getRuleGroup,
  preProcessRuleConfigsForTemplates,
  groupRuleConfigsByEffectiveDate,
  getCurrentDateString,
  getDateWithAddedDays,
  getJurisdictionRules,
  buildConfigsForTippedRules,
  filterRuleConfigsForType,
  getSplitShiftRuleDescription,
  isChangesSetCustomRules,
  areDefaultRules,
  getDefaultConfigsByRuleTypeAndJurisdictionPath,
  getDefaultPredPay,
  getDefaultMinorSchoolDayDef,
  getDefaultTippedMinWage,
  isPredictabilityPayRule,
  getLocalityByLocationStateAndSites,
} from '../utils/utils';
import LaborRuleTemplate from '../LaborRuleTemplate/LaborRuleTemplate';
import EditRuleForm from '../EditRuleForm/EditRuleForm';
import routes from '../../constants/routes';
import JurisdictionSitesDataGrid, {
  JurisdictionSitesRow,
} from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';
import StickyFooter from '../../labor/StickyFooter/StickyFooter';
import SaveRulesModal from '../modals/SaveRulesModal/SaveRulesModal';
import RestoreDefaultModal from '../modals/RestoreDefaultModal/RestoreDefaultModal';
import DeleteRulesModal from '../modals/DeleteRulesModal/DeleteRulesModal';
import { useDeleteUpcomingRuleConfigurations, useSaveNewRuleConfiguration } from '../requests/mutations';
import { ConfirmationFormValues } from '../modals/LaborRuleBaseConfirmationModal/LaborRuleBaseConfirmationModal';
import { RuleWithEffectiveDate } from '../RuleWithEffectiveDate/RuleWithEffectiveDate';
import {
  handleDelete,
  handleSaveAndDelete,
  handleRestoreDefaults,
  SaveAndDeleteHandlerProps,
} from './utils/formSubmitHandlers';
import { Exemptions } from '../Exemptions/Exemptions';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

export interface LaborRuleEditViewProps {
  jurisdictionPath: string;
  orgRuleConfigsLoading: boolean;
  sites: JurisdictionSitesRow[] | undefined;
  scopeOrganizedRules: Map<string, LaborRuleConfigData[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchConfigs: any;
  orgRuleConfigs: LaborRuleConfigData[];
  allDefaultRuleConfigs: LaborRuleConfigData[];
}

const getRulesForCurrentScopeAndRuleType = (
  scopeOrganizedRules: Map<string, LaborRuleConfigData[]>,
  siteId: string | null,
  locality: string | null,
  jurisdictionPath: string,
  ruleType: string
) => {
  let filteredConfigs = [];
  if (siteId) {
    filteredConfigs = scopeOrganizedRules.get(siteId) || [];
  } else if (locality) {
    filteredConfigs = scopeOrganizedRules.get(locality) || [];
  } else {
    filteredConfigs = getJurisdictionRules(scopeOrganizedRules, jurisdictionPath);
  }

  return filteredConfigs.filter((conf) => filterRuleConfigsForType(conf, ruleType));
};

const getRulesForCurrentEditView = (
  scopeOrganizedRules: Map<string, LaborRuleConfigData[]>,
  siteId: string | null,
  locality: string | null,
  jurisdictionPath: string,
  ruleType: string
) => {
  let filteredConfigs = getRulesForCurrentScopeAndRuleType(
    scopeOrganizedRules,
    siteId,
    locality,
    jurisdictionPath,
    ruleType
  );

  if (ruleType === WagesRuleTypes.TippedMinimumWageRule) {
    filteredConfigs = buildConfigsForTippedRules(filteredConfigs);
  }
  return filteredConfigs;
};

const getDefaultRuleFormValues = (
  template: string,
  allScopeRules: LaborRuleConfigData[],
  activeScopeRules: LaborRuleConfigData[],
  _ruleGroup: LaborRuleGroup,
  _ruleType: string
) => {
  // If no TippedMinWageRule currently active, build a form with current active (NonTipped) MinimumWageRule
  if (_ruleType === WagesRuleTypes.TippedMinimumWageRule && activeScopeRules.length === 0) {
    const activeMinimumWageRule = allScopeRules.filter(
      (x) => (x.rule as MinimumWageRule).isTipped === false && x.effectiveDate <= getCurrentDateString()
    )[0];
    return activeMinimumWageRule
      ? getRuleFormValues(
          template,
          [
            { ...activeMinimumWageRule, rule: { ...activeMinimumWageRule?.rule, isTipped: true } },
            activeMinimumWageRule,
          ],
          _ruleGroup,
          _ruleType
        )
      : [getDefaultTippedMinWage()];
  }
  if (_ruleType === SchedulingRuleTypes.PredictabilityPayRule && activeScopeRules.length === 0) {
    return [getDefaultPredPay()];
  }
  if (_ruleType === MinorRuleTypes.MinorSchoolDayDefinitionRule && activeScopeRules.length === 0) {
    return [getDefaultMinorSchoolDayDef()];
  }
  return getRuleFormValues(template, activeScopeRules, _ruleGroup, _ruleType);
};

export default function LaborRuleEditView(props: LaborRuleEditViewProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<Record<string, string>>();
  const { setSnackbarState } = useSnackbar();
  const theme = useTheme();
  const [openForm, setOpenForm] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openRestoreDefaultModal, setOpenRestoreDefaultModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [hasNewRuleButtonClicked, setHasNewRuleButtonClicked] = useState(false);
  const saveNewRuleConfigurationMutation = useSaveNewRuleConfiguration();
  const deleteRuleConfigurationsMutation = useDeleteUpcomingRuleConfigurations();
  const {
    jurisdictionPath,
    orgRuleConfigsLoading,
    sites,
    scopeOrganizedRules,
    refetchConfigs,
    allDefaultRuleConfigs,
    orgRuleConfigs,
  } = props;
  const jurisdictionNormal = jurisdictionPath.split('.').at(-1)?.replaceAll('_', ' ') || jurisdictionPath;
  const { lastLocation } = location.state
    ? (location.state as { lastLocation?: string })
    : ({} as { lastLocation?: string });
  const laborRuleEditViewBreadcrumbs = [t(`navItems.laborRules`), t(`laborRules.editRuleView.title`)];

  // Locality or Site selected for editing will be determined by there is a localityPath or referenceId in state respectively
  const referenceId = location?.state?.referenceId;
  const siteId = location?.state?.siteId;
  const localityPath = location?.state?.localityPath;
  const siteName = location?.state?.siteName;
  useEffect(() => {
    // If user attempted to directly this this URL or refreshed the page, redirect to main
    if (!lastLocation || (orgRuleConfigsLoading && lastLocation === location.pathname)) {
      history.push(routes.LABOR_RULES);
    } else {
      history.replace(location.pathname, { ...location.state, lastLocation: location.pathname });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLocation, history, location.pathname, orgRuleConfigsLoading]);

  const ruleType = location.pathname.split('/')[4];
  const ruleGroup = getRuleGroup(ruleType);
  const [ruleSetsToDelete, setRuleSetsToDelete] = useState<number[]>([]);

  const ruleConfigs = getRulesForCurrentEditView(scopeOrganizedRules, siteId, localityPath, jurisdictionPath, ruleType);
  const dateOrganizedRuleConfigs = groupRuleConfigsByEffectiveDate(ruleConfigs);
  const activeRuleConfigs =
    Object.keys(dateOrganizedRuleConfigs).sort((a, b) => a.localeCompare(b))[0] <= getCurrentDateString()
      ? dateOrganizedRuleConfigs[Object.keys(dateOrganizedRuleConfigs).sort((a, b) => a.localeCompare(b))[0]]
      : [];
  const defaultRuleConfigs = getDefaultConfigsByRuleTypeAndJurisdictionPath(
    allDefaultRuleConfigs,
    ruleType,
    jurisdictionPath
  );
  const customRuleConfigs = ruleConfigs.filter(
    (config) => config.defaultRuleId === null && !areDefaultRules(defaultRuleConfigs, [config])
  );
  const templateConfig =
    ruleType === SchedulingRuleTypes.PredictabilityPayRule
      ? getDefaultPredPay().rule
      : { ...activeRuleConfigs[0]?.rule, type: ruleType };
  const locality = getLocalityByLocationStateAndSites(location.state, sites);
  const templateString = getTemplateString(templateConfig, t, false, locality);
  const defaultValues = ruleGroup
    ? {
        hierarchyPath: localityPath ?? jurisdictionPath,
        ruleType: ruleType.split('.')[0],
        rules: getDefaultRuleFormValues(
          templateString,
          getRulesForCurrentScopeAndRuleType(scopeOrganizedRules, siteId, localityPath, jurisdictionPath, ruleType),
          activeRuleConfigs,
          ruleGroup,
          ruleType
        ),
        effectiveDate: getDateWithAddedDays(1).toISOString().split('T')[0],
        changeReason: RuleChangeReason.COMPANY_POLICY,
        siteId,
      }
    : {};

  const formContext = useForm<RuleFormValues>({
    defaultValues,
  });
  const { formState } = formContext;

  const { isDirty } = formState;
  const currentTypeOrgRules = orgRuleConfigs.filter((config) => config.rule.type === ruleType);
  const organizedSites = organizeSites(sites || [], currentTypeOrgRules, jurisdictionNormal, localityPath);
  const getAffectedSites = () => {
    let affectedSites: JurisdictionSitesRow[] | undefined;
    if (siteId) {
      affectedSites = sites?.filter((site) => site.id === siteId);
    } else {
      affectedSites = [...(organizedSites.get('affected') || [])];
    }
    return affectedSites || [];
  };
  const getExcludedSites = () => [...(organizedSites.get('excluded') || [])];
  /**
   * Determine whether to show Save modal or Delete modal
   * return true to show Save modal, false to show Delete modal
   */
  const showSaveModal = () => {
    const haveNewRulesAdded = hasNewRuleButtonClicked && formContext.getValues().rules.length > 0;
    if (ruleSetsToDelete.length > 0 && !haveNewRulesAdded) {
      return false;
    }
    return true;
  };

  const onNewRuleButtonClick = () => {
    setOpenForm(true);
    setHasNewRuleButtonClicked(true);
  };

  const handleSaveButtonClicked = () => {
    if (showSaveModal()) {
      setOpenSaveModal(true);
    } else {
      setOpenDeleteModal(true);
    }
  };

  const deleteUpcomingRuleSet = (changesetIds: number[]) => {
    setRuleSetsToDelete([...ruleSetsToDelete, ...changesetIds]);
  };

  const deleteEndAdornment = (changesetIds: number[]) => (
    <IconButton
      aria-label='delete upcoming rule changes'
      data-testid='delete-upcoming-rule-changeset-button'
      onClick={() => deleteUpcomingRuleSet(changesetIds)}
    >
      <DeleteIcon />
    </IconButton>
  );

  const getUpcomingRuleDivs = (
    changeSets: Dictionary<LaborRuleConfigData[]>,
    _ruleType: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): { id: number; div: ReactElement<any, string | JSXElementConstructor<any>> }[] => {
    const upcomingRules: { id: number; div: ReactElement }[] = [];
    each(changeSets, (changeset, changeSetEffectiveDate) => {
      if (changeSetEffectiveDate > getCurrentDateString()) {
        const changeSetIds = changeset.map((config) => config.changesetId);
        const dateSplit = changeSetEffectiveDate.split('-');
        const processedConfigs = preProcessRuleConfigsForTemplates(changeset, _ruleType);
        const div = (
          <Grid
            item
            sx={{ marginTop: 3 }}
            xs={12}
            // eslint-disable-next-line react/no-array-index-key
            key={`upcoming-rule-changeset-${changeSetEffectiveDate}`}
            data-testid={`upcoming-rule-template-div-${changeSetEffectiveDate}`}
          >
            <Grid
              container
              item
              data-testid='rule-changeset-header-and-action'
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Grid item>
                <Typography sx={{ fontWeight: 500 }}>
                  {isChangesSetCustomRules(changeset) && !areDefaultRules(defaultRuleConfigs, changeset)
                    ? t('laborRules.editRuleView.customRuleLabel')
                    : t('laborRules.editRuleView.jurisdictionDefaultLabel')}
                  {`, ${t('laborRules.editRuleView.effective')} ${dateSplit[1]}/${dateSplit[2]}/${dateSplit[0]}`}
                </Typography>
              </Grid>
              <Grid item>{deleteEndAdornment(changeSetIds)}</Grid>
            </Grid>
            {processedConfigs.map((config: LaborRuleConfigData, index: number) => (
              <LaborRuleTemplate
                // eslint-disable-next-line react/no-array-index-key
                key={`edit-rule-${index}-${config.rule.type}-${config.id}`}
                id={index}
                rule={config.rule}
                sx={{ m: 0, mt: 2 }}
                locality={locality}
                focusMode
              />
            ))}
            {isPredictabilityPayRule(processedConfigs[0].rule) && (
              <Exemptions selectedExemptions={processedConfigs[0].rule.exemptions} />
            )}
          </Grid>
        );
        upcomingRules.push({ id: changeset[0].changesetId, div });
      }
    });
    return upcomingRules;
  };
  const upcomingRuleDivs = getUpcomingRuleDivs(dateOrganizedRuleConfigs, ruleType);

  const affectedSitesCopy =
    (localityPath && t('laborRules.editRuleView.affectedSitesLocality')) ||
    (siteId && ' ') ||
    t('laborRules.editRuleView.affectedSitesCaption');

  const saveCtaAction = (confirmData: ConfirmationFormValues) =>
    formContext.handleSubmit(async (data: RuleFormValues) =>
      handleSaveAndDelete({
        data,
        formData: confirmData,
        changesetsToDelete: ruleSetsToDelete,
        handleSuccessProps: {
          ruleName: t(`laborRules.rules.${ruleType}.name`),
          effectiveDate: confirmData.effectiveDate,
          jurisdictionName: jurisdictionNormal,
          push: history.push,
          setOpenModal: setOpenSaveModal,
          refetchConfigs,
          setSnackbarState,
        },
        saveNewRuleConfigurationMutation,
        deleteRuleConfigurationsMutation,
      })
    )();

  const restoreCtaAction = (confirmData: ConfirmationFormValues) =>
    formContext.handleSubmit(async (data: RuleFormValues) => {
      const handleSuccessProps = {
        ruleName: t(`laborRules.rules.${ruleType}.name`),
        effectiveDate: confirmData.effectiveDate,
        jurisdictionName: jurisdictionNormal,
        push: history.push,
        setOpenModal: setOpenRestoreDefaultModal,
        refetchConfigs,
        setSnackbarState,
      };

      // Get only the upcoming custom rules. Not the current ones.
      const upcomingChangeSetIds = customRuleConfigs
        .filter((config) => config.effectiveDate > getCurrentDateString())
        .map((config) => config.changesetId);

      const handlerProps: SaveAndDeleteHandlerProps = {
        data,
        formData: confirmData,
        changesetsToDelete: upcomingChangeSetIds,
        handleSuccessProps,
        saveNewRuleConfigurationMutation,
        deleteRuleConfigurationsMutation,
      };

      handleRestoreDefaults(defaultRuleConfigs, handlerProps);
    })();

  const deleteCtaAction = (confirmData: ConfirmationFormValues) =>
    formContext.handleSubmit((data: RuleFormValues) => {
      const successProps = {
        ruleName: t(`laborRules.rules.${ruleType}.name`),
        effectiveDate: confirmData.effectiveDate,
        jurisdictionName: jurisdictionNormal,
        push: history.push,
        setOpenModal: setOpenDeleteModal,
        refetchConfigs,
        setSnackbarState,
      };
      handleDelete(ruleSetsToDelete, successProps, deleteRuleConfigurationsMutation);
    })();
  return (
    <Grid data-testid='lr-edit-view-container' container direction='column'>
      <Breadcrumbs breadcrumbLabels={laborRuleEditViewBreadcrumbs} styleOverrides={{ pl: 0, zIndex: 1 }} />
      <FormContainer formContext={formContext}>
        {openRestoreDefaultModal && (
          <RestoreDefaultModal
            ruleType={ruleType}
            defaultRules={defaultRuleConfigs}
            handleClose={() => setOpenRestoreDefaultModal(false)}
            handleRestore={restoreCtaAction}
            loading={
              saveNewRuleConfigurationMutation.isLoading ||
              deleteRuleConfigurationsMutation.isLoading ||
              orgRuleConfigsLoading
            }
          />
        )}
        {openSaveModal && (
          <SaveRulesModal
            loading={saveNewRuleConfigurationMutation.isLoading || orgRuleConfigsLoading}
            handleClose={() => setOpenSaveModal(false)}
            handleSave={saveCtaAction}
          />
        )}
        {openDeleteModal && (
          <DeleteRulesModal
            handleClose={() => setOpenDeleteModal(false)}
            handleDelete={deleteCtaAction}
            loading={deleteRuleConfigurationsMutation.isLoading || orgRuleConfigsLoading}
          />
        )}
      </FormContainer>
      <Header dataTestId='lr-edit-header' variant='h5' title={t(`laborRules.editRuleView.title`)} />
      {siteId && (
        <Grid
          data-testid='labor-rules-edit-jurisdiction-grid-custom-description'
          container
          sx={{ pb: '1rem', maxWidth: '37.5rem' }}
        >
          <Grid item>
            <Typography>{t('laborRules.editRuleView.customRuleDescription')}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid data-testid='lr-location-info-div' container item direction='row' spacing={12}>
        <Grid data-testid='labor-rules-edit-jurisdiction-grid' item>
          <Typography variant='caption' sx={{ color: theme.palette.grey[600] }}>
            {t('laborRules.editRuleView.jurisdictionLabel')}
          </Typography>
          <Typography data-testid='jurisdiction-name-value'>{jurisdictionNormal}</Typography>
        </Grid>
        {localityPath && (
          <Grid data-testid='labor-rules-edit-locality-grid' item>
            <Typography variant='caption' sx={{ color: '#656565' }}>
              {t('laborRules.editRuleView.localityLabel')}
            </Typography>
            <Typography data-testid='locality-name-value'>
              {localityPath.split('.').at(-1)?.replaceAll('_', ' ')}
            </Typography>
          </Grid>
        )}
        {referenceId && (
          <Grid data-testid='labor-rules-edit-site-grid' item>
            <Typography variant='caption' sx={{ color: theme.palette.grey[600] }}>
              {t('laborRules.editRuleView.siteIdLabel')}
            </Typography>
            <Typography data-testid='site-id-value'>{referenceId}</Typography>
          </Grid>
        )}
        {siteName && (
          <Grid data-testid='labor-rules-edit-site-grid' item>
            <Typography variant='caption' sx={{ color: theme.palette.grey[600] }}>
              {t('laborRules.editRuleView.siteNameLabel')}
            </Typography>
            <Typography data-testid='site-name-value'>{siteName}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid item my={2} data-testid='labor-rules-edit-top-divider-grid'>
        <Divider />
      </Grid>
      <Grid item data-testid='lr-edit-view-subheader-div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6'>{t(`laborRules.rules.${ruleType}.name`)}</Typography>
        {customRuleConfigs.length > 0 && defaultRuleConfigs.length > 0 && (
          <Button
            onClick={() => setOpenRestoreDefaultModal(true)}
            data-testid='lr-edit-restore-default-button'
            aria-label='Restore default'
            color='error'
          >
            <Typography variant='body2'> {t('laborRules.editRuleView.restoreDefault')} </Typography>
          </Button>
        )}
      </Grid>
      <Grid container item data-testid='lr-edit-current-and-upcoming-rules-container'>
        <Grid item data-testid='current-rules-div' sx={{ marginTop: 3 }} xs={12}>
          {orgRuleConfigsLoading ? (
            <Box>
              <Skeleton sx={{ width: 372, height: 50 }} />
              <Skeleton sx={{ width: '100%', height: 90 }} />
            </Box>
          ) : (
            <RuleWithEffectiveDate
              effectiveDate={Object.keys(dateOrganizedRuleConfigs).sort((a, b) => a.localeCompare(b))[0]}
              currentConfigs={activeRuleConfigs}
              ruleType={ruleType}
              currentDefaultRuleConfigs={defaultRuleConfigs}
              locality={locality}
            />
          )}
        </Grid>
        {upcomingRuleDivs?.filter((div) => !ruleSetsToDelete.includes(div.id)).map(({ id, div }) => div)}
      </Grid>
      <Grid item my={3} data-testid='lr-edit-new-form-divider-grid'>
        <Divider />
      </Grid>
      <Grid container item data-testid='lr-edit-new-form-div'>
        <Grid container item>
          {openForm ? (
            <Grid data-testid='lre-edit-opened-rule-form-container' container item xs={12}>
              <Grid container item xs={12}>
                <Grid item>
                  <Typography sx={{ fontWeight: '500' }}>
                    {t('laborRules.editRuleView.newRuleFormHeaderText')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormContainer formContext={formContext}>
                    {templateString && (
                      <EditRuleForm
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        generateEmptyConfig={() => getEmptyRuleConfig(templateString, ruleGroup, ruleType)}
                        onRuleDelete={() => {
                          if (
                            ruleType === WagesRuleTypes.MinimumWageRule ||
                            ruleType === WagesRuleTypes.TippedMinimumWageRule ||
                            ruleType === MinorRuleTypes.MinorSchoolDayDefinitionRule
                          )
                            setOpenForm(false);
                        }}
                        locality={locality}
                      />
                    )}
                  </FormContainer>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              data-testid='lr-edit-schedule-rule-button-template'
              sx={{
                background: theme.palette.background.default,
                padding: 1.8,
                borderRadius: 1,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: theme.palette.divider,
              }}
            >
              <Grid item xs={12}>
                <Button
                  data-testid='open-form-button'
                  aria-label='Open new rule configuration form'
                  sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
                  onClick={onNewRuleButtonClick}
                >
                  <AddCircleIcon />
                  <Typography
                    variant='body2'
                    sx={{ paddingLeft: 1, color: theme.palette.text.primary, fontWeight: 500 }}
                  >
                    {t('laborRules.editRuleView.addNewRuleButtonLabel')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        {ruleType === SchedulingRuleTypes.SplitShiftRule && (
          <Grid data-testid='split-shift-rule-description' item mt={3}>
            {getSplitShiftRuleDescription(t)}
          </Grid>
        )}
      </Grid>
      <Grid container item data-testid='lre-edit-tables-div' direction='column' sx={{ mt: 5, mb: 8 }} spacing={2}>
        <Grid item data-testid='lre-edit-affected-sites-table'>
          <Typography sx={{ fontWeight: 500 }}> {t('laborRules.editRuleView.affectedSitesLabel')} </Typography>
          <Typography variant='body2'>{affectedSitesCopy}</Typography>
          <JurisdictionSitesDataGrid
            getRowHeight={() => 32}
            editModeVariant
            jurisdictionPath={jurisdictionPath}
            ruleGroup={LaborRuleGroup.SCHEDULING}
            sites={getAffectedSites()}
            allRules={currentTypeOrgRules}
          />
        </Grid>
        {!siteId && (
          <Grid item data-testid='lre-edit-excluded-sites-table'>
            <Typography sx={{ fontWeight: 500 }}> {t('laborRules.editRuleView.excludedSitesLabel')} </Typography>
            <Typography variant='body2'>{t('laborRules.editRuleView.excludedSitesCaption')}</Typography>
            <JurisdictionSitesDataGrid
              getRowHeight={() => 32}
              editModeVariant
              sites={getExcludedSites()}
              jurisdictionPath={jurisdictionPath}
              ruleGroup={LaborRuleGroup.SCHEDULING}
              allRules={currentTypeOrgRules}
            />
          </Grid>
        )}
      </Grid>
      <StickyFooter>
        <Grid container item my={1} spacing={2} justifyContent='flex-end'>
          <Grid item>
            <Button data-testid='cancel-button' aria-label='Go back' onClick={() => history.push(routes.LABOR_RULES)}>
              {t('buttonText.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-testid='save-button'
              aria-label='Save'
              variant='contained'
              disabled={
                (!isDirty && ruleSetsToDelete.length === 0) ||
                (ruleSetsToDelete.length === 0 && formContext.getValues().rules.length === 0)
              }
              onClick={handleSaveButtonClicked}
            >
              {t('buttonText.save')}
            </Button>
          </Grid>
        </Grid>
      </StickyFooter>
    </Grid>
  );
}
