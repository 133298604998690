import { SiteInfo } from '@cbo/shared-library';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import StoreMallDirectory from '@mui/icons-material/StoreMallDirectory';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import createFormTextField, { StartAdornmentType } from '../../components/FormTextFields/FormTextFields';
import { baseRoutes } from '../../constants/routes';
import { useSites } from '../../contexts/siteContext';
import { useUsers } from '../../contexts/userContext';
import siteSort from '../../utils/siteUtils/siteSort';
import { getSiteButtonText } from '../utils/siteUtils';

interface SiteSelectorProps {
  isMobile: boolean;
  isOrgView: boolean;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
    textAlign: 'center',
  },
}));

function SiteSelector({ isMobile, isOrgView }: SiteSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUsers();
  const history = useHistory();
  const theme = useTheme();
  const { sites, selectedSite, updateSelectedSite, isSiteFiltering, multiSelectedSites } = useSites();
  const [allSitesData, setAllSitesData] = useState<SiteInfo[]>([]);
  const [siteSearch, setSiteSearch] = useState<string>('');
  const allSites = useMemo(() => siteSort(Object.values(sites)), [sites]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
    setSiteSearch('');
  };

  const handleSiteSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (user && user.bslAuth) {
      const tempSites = siteSort(user.bslAuth.sites);
      setAllSitesData(tempSites);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSiteSelection = (site: SiteInfo) => {
    if (site.enterpriseUnitId !== selectedSite.enterpriseUnitId) {
      const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

      // Check if the current route has a GUID, which will inevitably be site specific. If so
      // we want to route the user away so they won't encounter a 404
      if (uuidRegex.test(window.location.href)) {
        // Close the site selector dialog
        handleClose();

        const baseRoute = baseRoutes.find((path) => window.location.pathname.includes(path));
        if (baseRoute) {
          // The appended ?siteChange optional param will indicate that only this routing
          // event should trigger a site change if user accepts it with unsaved changes.
          const path = `${baseRoute}?siteChange`;
          // If the user has unsaved changes, this will track if they choose to cancel the
          // navigation or continue. If they continue, this will update the site automatically.
          const unregister = history.listen((location) => {
            if (location.pathname + location.search === path) updateSelectedSite(site);

            // TBH this is a bit of a hack, to remove the appended ?siteChange
            window.history.replaceState(null, '', window.location.pathname);

            // This unregister is super important, because we only want to check the first route change
            // that happens after this function is called, and if that route change doesn't result in
            // the user going to that url we expect we don't want the site to change on the following route change
            unregister();
          });
          history.push(path);
        }
      } else {
        updateSelectedSite(site);
      }
    }
  };

  const handleChange = useCallback(
    (searchStr: string) => {
      setSiteSearch(searchStr);
    },
    [setSiteSearch]
  );

  const { register } = useForm();

  const buttonText = !selectedSite.name?.length
    ? t('global.loading')
    : getSiteButtonText(
        selectedSite.name ?? '',
        multiSelectedSites,
        allSites.length,
        isSiteFiltering,
        true,
        t('globalFilters.sites'),
        t('globalFilters.allSites')
      );

  return isOrgView ? (
    <CustomTooltip arrow title={t('global.organizationViewTooltipText')}>
      <span>
        <Button disabled data-testid='org-view-tooltip-btn' startIcon={<StoreMallDirectory />} endIcon={<UnfoldMore />}>
          {t('navItems.organizationView')}
        </Button>
      </span>
    </CustomTooltip>
  ) : (
    <>
      <LoadingButton
        onClick={handleSiteSelectorClick}
        aria-label='Site Selection'
        data-testid='site-selector-btn'
        startIcon={selectedSite?.enterpriseUnitId !== '' ? <StoreMallDirectory /> : <BusinessIcon />}
        endIcon={<UnfoldMore />}
        disabled={isSiteFiltering}
        sx={{
          color: 'text.secondary',
          fontWeight: 'normal',
          '&:visited, &:focus, &:active, &:hover, &:focus:hover': {
            color: 'text.secondary',
            backgroundColor: 'transparent',
          },
        }}
        loading={!selectedSite.name?.length}
        loadingPosition='start'
      >
        {isMobile ? null : buttonText}
      </LoadingButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        open={open}
        onClose={handleClose}
        data-testid='site-selector'
        sx={{
          '.MuiPaper-root': {
            boxShadow:
              '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          },
          zIndex: 1450,
        }}
      >
        <Box width='360px'>
          <Box display='flex' alignItems='center' justifyContent='space-between' margin='20px'>
            <Typography variant='subtitle1'>{t('global.sites')}</Typography>
            <IconButton aria-label={t('buttonText.close')} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box maxHeight='330px' overflow='auto' margin='20px'>
            <Box pb='20px'>
              <FormContainer>
                {createFormTextField(
                  {
                    name: 'site-search',
                    label: t('global.search'),
                    startAdornmentType: StartAdornmentType.SEARCH_ICON,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value),
                  },
                  { t, tKey: 'global' },
                  register
                )}
              </FormContainer>
            </Box>

            <RadioGroup name='sites'>
              {allSitesData
                .filter(
                  (site: SiteInfo) =>
                    site.referenceId?.toLowerCase().includes(siteSearch.toLowerCase()) ||
                    site.name?.toLowerCase().includes(siteSearch.toLowerCase())
                )
                .map((option) => (
                  <FormControlLabel
                    key={option.enterpriseUnitId}
                    value={option.enterpriseUnitId}
                    onClick={() => handleSiteSelection(option)}
                    control={
                      <Radio
                        size='small'
                        checked={option?.enterpriseUnitId === selectedSite?.enterpriseUnitId}
                        data-testid='site-selection-option'
                        sx={{ marginBottom: '8px' }}
                      />
                    }
                    sx={{ marginX: '0px', marginBottom: '8px' }}
                    label={
                      <>
                        <Typography variant='body1'>{option.name}</Typography>
                        <Typography variant='body2' color={theme.palette.text.secondary}>
                          {option.referenceId}
                        </Typography>
                      </>
                    }
                  />
                ))}
            </RadioGroup>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default SiteSelector;
