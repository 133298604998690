import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Package } from '@cbo/shared-library';
import { usePackages } from '../../contexts/packageContext';
import { hasPackagePermissions } from '../../lib/permissions';
import PlaceholderPage from '../../../pages/PlaceholderPage';

type PackagePermissionProps = {
  packagesRequired: Package[];
  children: JSX.Element;
  hideChild?: boolean;
  dataTestId?: string;
};

function PackagePermissionWrapper({ children, packagesRequired, hideChild, dataTestId = '' }: PackagePermissionProps) {
  const history = useHistory();
  const packages = usePackages();
  const [hasPermission, setHasPermission] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (packages.packagesSet) {
      if (!hasPackagePermissions(packages, packagesRequired)) {
        setHasPermission(false);
      } else {
        setHasPermission(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagesRequired, history, hideChild, packages]);

  /* due to the async behavior of the application, we run into a race condition where it will show
   the not authorized page for a split second before the packages are set */
  if (hasPermission === undefined) {
    return <div data-testid='packages-not-set' />;
  }

  if (hasPermission) {
    return children;
  }

  return hideChild ? (
    <div data-testid='hidden-forbidden' />
  ) : (
    <PlaceholderPage contentText='Not authorized' dataTestId={dataTestId} />
  );
}

PackagePermissionWrapper.defaultProps = {
  hideChild: false,
  dataTestId: 'not-authorized-content',
};

export default PackagePermissionWrapper;
