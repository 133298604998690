import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useGridApiContext, GridRenderCellParams, GridGroupNode } from '@mui/x-data-grid-premium';
import { SxProps } from '@mui/system';
import { Link } from 'react-router-dom';

export interface CustomTreeGroupCellProps extends GridRenderCellParams {
  cellText: string;
  cellLink?: string;
  isLink?: boolean;
  showDescendantCount?: boolean;
  textStyle?: SxProps;
}

function CustomTreeGroupCell(params: CustomTreeGroupCellProps) {
  const apiRef = useGridApiContext();
  const { cellText, cellLink, isLink, showDescendantCount, textStyle, ...rest } = params;
  const children = apiRef.current.getRowGroupChildren({
    groupId: rest.rowNode.id,
    applyFiltering: true,
    applySorting: true,
  });

  const handleClick: ButtonProps['onClick'] = (event) => {
    apiRef.current.setRowChildrenExpansion(
      rest.id,
      !apiRef.current.getRowNode<GridGroupNode>(rest.id)?.childrenExpanded
    );
    apiRef.current.setCellFocus(rest.id, rest.field);
    event.stopPropagation();
  };

  return (
    <Box
      sx={{ marginLeft: rest.rowNode.depth * 10, display: 'flex', alignItems: 'center' }}
      data-testid='tree-group-cell'
    >
      {children && children.length > 0 && (
        <IconButton onClick={handleClick}>
          {apiRef.current.getRowNode<GridGroupNode>(rest.id)?.childrenExpanded ? (
            <ExpandMoreIcon data-testid='expanded-row-icon' />
          ) : (
            <ChevronRightIcon data-testid='right-arrow-icon' />
          )}
        </IconButton>
      )}
      {isLink && cellLink ? (
        <Link to={cellLink}>
          <Typography variant='body2' color='text/primary' sx={{ marginLeft: children ? '5px' : '45px', ...textStyle }}>
            {cellText}
            {showDescendantCount && children && children.length > 0 ? ` (${children.length})` : ''}
          </Typography>
        </Link>
      ) : (
        <Typography variant='body2' color='text/primary' sx={{ marginLeft: children ? '5px' : '45px', ...textStyle }}>
          {cellText}
        </Typography>
      )}
    </Box>
  );
}

export default CustomTreeGroupCell;
