import { FeatureFlag } from '@cbo/shared-library';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type FeatureFlagKey = (typeof FeatureFlag)[keyof typeof FeatureFlag] | 'cboLaborRearchitecture';

export function useFeatureFlag(key: FeatureFlagKey): boolean {
  const flags = useFlags();
  return flags?.[key] ?? false;
}

export function useFeatureFlags(...keys: FeatureFlagKey[]) {
  const flags = useFlags() ?? {};
  return keys.map((key) => flags?.[key] ?? false);
}
